import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=18348cb7&scoped=true&"
import script from "./tabs.vue?vue&type=script&lang=js&"
export * from "./tabs.vue?vue&type=script&lang=js&"
import style0 from "./tabs.vue?vue&type=style&index=0&id=18348cb7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18348cb7",
  null
  
)

export default component.exports